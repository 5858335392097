@import 'src/styles/_variables';
@import '~ht-styleguide/build/grid_classes';
@import '~ht-styleguide/build/spacing';

.outerWrapper {
  position: fixed;
  z-index: $ht-z-index-snackbar;
  display: flex;
  justify-content: center;
  top: $ht-topnav-mobile-height;
  right: 0;
  left: 0;
  max-width: $ht-max-width;
  @include spacing(padding, 2 3);

  &.hide {
    display: none;
  }

  .innerWrapper {
    width: 100%;
    max-width: $ht-max-width;
  }

  @include with-md-grid {
    top: $ht-topnav-tablet-height;
    @include spacing(padding, 2.5 4);
  }

  @include with-lg-grid {
    top: $ht-topnav-desktop-height;
    @include spacing(padding, 3 5);
  }
}
