@import '~ht-styleguide/build/grid_classes';
@import '~ht-styleguide/build/spacing';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.toastContainer {
  /*
    We're stretching the fixed container to touch the edges of the
    browser. This was done so that the toasts can extend into this
    fixed container.

    Since this container will extend to the browser edges, we've
    disabled pointer-events so the user can interact with the page
    that immediately surrounds a toast. Pointer-events are reset in
    the toast class.
  */
  right: 0;
  bottom: 0;
  left: 0;
  transform: none;
  pointer-events: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: bottom 0.2s linear;
  width: auto;
  @include spacing(padding, 0 2);
}

.toast {
  pointer-events: auto; // unset the pointer-events that was sent in the parent container
  min-height: auto;
  @include spacing(padding, 1.75 2);

  margin-bottom: 0; // override
  background-color: rgba($ht-navy-800, 0.9);
  border-radius: map-get($ht-panel-border-radius, medium);
  color: $ht-navy-000;
  font-size: 16px;
  font-family: $ht-font-family;
  font-weight: $ht-font-weight-medium;
  flex-direction: row;
  display: flex;
}

.closeButton {
  font-size: 12px;
  color: $ht-navy-300;

  @include with-hover {
    color: $ht-navy-000;
  }
}

// container that holds the message
.toastBody {
  padding: 0;
  margin: 0;
}

// Custom animation
@mixin transform {
  transform: translate3d(0, 0, 0);
}

@keyframes up {
  from {
    transform: translate3d(0, 50vh, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    @include transform;

    opacity: 1;
  }
}

@keyframes down {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 50vh, 0);
    opacity: 0;
  }
}

@mixin animate {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

.animateUp {
  @include animate;

  animation-name: up;
}

.animateDown {
  @include animate;

  animation-name: down;
}
