@import "src/styles/typography.scss";
@import '~ht-styleguide/build/grid_classes';
@import '~ht-styleguide/build/spacing';
@import '~ht-styleguide/build/typography_placeholders';
@import '~ht-styleguide/build/colors';
@import 'src/styles/mixins';

.gladlyPlaceholder {
  display: inline-block;
  position: fixed;
  bottom: 14px;
  right: 14px;
  z-index: 1000000;
  cursor: pointer;
  transition: transform 0.2s ease;

  width: 51px;
  height: 51px;

  &:hover {
    transform: scale(1.05);
  }

  &.isLoading {
    img {
      opacity: 0.5;
    }
  }
}

.iconWrapper {
  .chat-icon {
    .bg-rect {
      transition: fill 0.2s ease;
    }
    .line {
      transition: fill 0.2s ease;
    }
  }

  &:hover {
    .chat-icon {
      .bg-rect {
        fill: #4089CB;
      }
      .line {
        fill: #4089CB;
      }
    }
  }
}

.chatLoader {
  position: absolute;
  bottom: 22px;
  right: 22px;
}



